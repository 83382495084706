import React from "react"
import * as Reach from "@reach/tabs"
import css from "@styled-system/css"

import { Box, Text } from "components"

// For some reason arrow keys aren't working and I can't explain why. Look into this further.
// https://reacttraining.com/reach-ui/tabs

const Tab = ({ children, isSelected, ref, ...rest }) => {
  if (children)
    return (
      <Reach.Tab
        data-component-id="tab"
        css={css({
          position: "relative",
          m: 0,
          px: "spacing.4",
          py: "spacing.3",
          border: 0,
          borderRadius: 9999,
          bg: isSelected ? "primary" : "transparent",
          appearance: "none",
          cursor: "pointer",
        })}
        {...rest}
      >
        <Text
          data-component-id="tab.label"
          as="span"
          size={300}
          fontFamily="regular"
          fontWeight={600}
          textAlign="center"
          color={isSelected ? "#fff" : "text"}
          css={{ textTransform: "uppercase" }}
        >
          {children}
        </Text>
      </Reach.Tab>
    )
  else return null
}

const Tabs = ({ tabs, alignTabs, ...rest }) => {
  return (
    <Reach.Tabs data-component-id="tabs" {...rest}>
      {/* Tabs */}

      <Reach.TabList
        data-component-id="tabs.list"
        css={css({
          display: "flex",
          flexWrap: "wrap",
          justifyContent: alignTabs,
          width: "100%",
          m: "-8px",
          pl: "8px",
        })}
      >
        {tabs.map((tab, index) => {
          if (tab) {
            return <Tab key={index}>{tab.label}</Tab>
          } else {
            return null
          }
        })}
      </Reach.TabList>
      {/* Panels */}
      <Reach.TabPanels data-component-id="tabs.panels">
        {tabs.map((tab, index) => {
          if (tab) {
            return (
              <Box
                as={Reach.TabPanel}
                data-component-id="tabs.panels.item"
                key={index}
                css={{ "&:focus": { outline: 0 } }}
              >
                {tab.content}
              </Box>
            )
          } else {
            return null
          }
        })}
      </Reach.TabPanels>
    </Reach.Tabs>
  )
}

Tabs.defaultProps = {
  alignTabs: "flex-start",
}

export default Tabs
