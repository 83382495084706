import React from "react"

import { Box, ColorMode, Heading, Text } from "components"

const Alert = ({ heading, text }) => (
  <ColorMode mode="dark">
    <Box p={[3, 4]} bg="background">
      {heading && (
        <Heading mb="layout.1" size={100}>
          {heading}
        </Heading>
      )}
      {text && <Text>{text}</Text>}
    </Box>
  </ColorMode>
)

export default Alert
