// Sidebar, primarily used for menu items

import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import css from "@styled-system/css"

import "@reach/dialog/styles.css"

import { Box } from "../../box"
import { ColorMode } from "../../colorMode"

const BG_DURATION = 0.3
const MENU_DURATION = 0.7
const EASE_OUT_QUINT = [0.23, 1, 0.32, 1]

const CloseButton = ({ ...rest }) => (
  <button
    title={`Close`}
    css={css({
      position: "absolute",
      top: 12,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "text",
      width: [48, 48],
      height: [48, 48],
      opacity: 0.7,
      m: 0,
      p: 0,
      border: 0,
      bg: "transparent",
      appearance: "none",
      cursor: "pointer",
      "&:focus": { outline: 0 },
      "::-moz-focus-inner": { border: 0 },
    })}
    {...rest}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentColor"
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </button>
)

function Sidebar({ children, showDialog, setShowDialog }) {
  return (
    <ColorMode mode="dark">
      <AnimatePresence>
        {showDialog && (
          <DialogOverlay
            onDismiss={() => setShowDialog(!showDialog)}
            css={css({
              zIndex: 10000,
              bg: "transparent",
              cursor: "pointer",
              overflow: "hidden",
            })}
          >
            <motion.div
              variants={{
                initial: {
                  opacity: 0,
                },
                enter: {
                  opacity: 1,
                  transition: {
                    duration: BG_DURATION,
                  },
                },
                exit: {
                  opacity: 0,
                  transition: { duration: BG_DURATION },
                },
              }}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <div
                css={css({
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: -1,
                  width: "100%",
                  height: "100%",
                  bg: "#000",
                  opacity: 0.5,
                })}
              />
            </motion.div>
            <motion.div
              variants={{
                initial: {
                  scaleX: 0,
                },
                enter: {
                  scaleX: 1,
                  transition: {
                    duration: MENU_DURATION,
                    ease: EASE_OUT_QUINT,
                  },
                },
                exit: {
                  scaleX: 0,
                  transition: {
                    duration: MENU_DURATION / 3,
                    ease: EASE_OUT_QUINT,
                  },
                },
              }}
              initial="initial"
              animate="enter"
              exit="exit"
              style={{ transformOrigin: "right" }}
            >
              <DialogContent
                css={css({
                  position: "relative",
                  pointerEvents: "all",
                  zIndex: 3,
                  width: "100%",
                  maxWidth: [320, 320 + 64],
                  minHeight: "100vh",
                  my: 0,
                  mr: 0,
                  ml: "auto",
                  p: 0,
                  cursor: "auto",
                  bg: "background",
                })}
              >
                <Box
                  css={css({
                    position: "absolute",
                    pointerEvents: "all",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    WebkitOverflowScrolling: "touch",
                  })}
                >
                  <motion.div
                    variants={{
                      initial: {
                        opacity: 0,
                      },
                      enter: {
                        opacity: 1,
                        transition: {
                          duration: MENU_DURATION / 1,
                          ease: EASE_OUT_QUINT,
                          delay: (MENU_DURATION / 5) * 3,
                        },
                      },
                      exit: {
                        opacity: 0,
                        transition: {
                          duration: MENU_DURATION / 4,
                          ease: EASE_OUT_QUINT,
                        },
                      },
                    }}
                  >
                    {children}
                  </motion.div>
                </Box>

                <CloseButton onClick={() => setShowDialog(!showDialog)} />
              </DialogContent>
            </motion.div>
          </DialogOverlay>
        )}
      </AnimatePresence>
    </ColorMode>
  )
}

export default Sidebar
