import React from "react"
import css from "@styled-system/css"

import Image from "gatsby-image/withIEPolyfill"

import {
  Box,
  ColorMode,
  Flex,
  Heading,
  Shape,
  RichText,
  Wrapper,
} from "components"

// A (presumably) single-use component, to hold three columns of information
// for use on the Stakeholders / Our Key Relationships page.

const IconBlock = ({ heading, description, graphic }) => (
  <>
    {(heading || description || graphic) && (
      <Box>
        <Shape size={[64, 96]} bg="primary" mb={["layout.2", "layout.4"]}>
          {graphic && graphic.fluid && (
            <Image
              fluid={graphic.fluid}
              alt={graphic.alt}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </Shape>

        <Heading
          children={heading}
          as="h4"
          size={600}
          mb={["layout.1", "layout.2"]}
        />
        <RichText size={500} content={{ html: description }} />
      </Box>
    )}
  </>
)

const IconGrid = ({ title, data }) => (
  <ColorMode mode={data.backgroundColor === "Black" ? "dark" : "light"}>
    {/* Thin text box, kept near the left of the screen. */}
    <Box
      className="icons-slice"
      as="section"
      position="relative"
      my={
        !(
          data.backgroundColor === "Gray" || data.backgroundColor === "Black"
        ) && ["layout.6", "layout.8"]
      }
    >
      {/* ~~~~~~~~~~ BACKGROUND ~~~~~~~~~~ */}
      {data.backgroundColor !== "White" && (
        <Box
          position="absolute"
          top={0}
          left={[0, 32]}
          width={["100%", "calc(100% - 64px)"]}
          height="100%"
          bg={data.backgroundColor === "Gray" ? "wash" : "background"}
        />
      )}

      {/* ~~~~~~~~~~ CONTENT ~~~~~~~~~~ */}
      <Wrapper
        position="relative"
        py={
          (data.backgroundColor === "Gray" ||
            data.backgroundColor === "Black") && ["layout.6", "layout.8"]
        }
      >
        {/* Title */}
        {title && <Heading children={title} pb="layout.4" />}
        <Box overflow="hidden">
          {data.items && (
            <Flex
              flexWrap="wrap"
              m={["-16px", "-32px"]}
              css={css({
                "> *": {
                  width: ["100%", "33.333%"],
                  p: ["16px", "32px"],
                },
              })}
            >
              {data.items.map(({ heading, description, graphic }, index) => (
                <IconBlock
                  heading={heading && heading.text}
                  description={description && description.html}
                  graphic={graphic}
                  key={"iconBlock" + index}
                />
              ))}
            </Flex>
          )}
        </Box>
      </Wrapper>
    </Box>
  </ColorMode>
)

export default IconGrid
