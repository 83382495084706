import React, { useState } from "react"
import css from "@styled-system/css"

import { Box, Flex, Shape, Text } from "components"

function Button({ backArrow, children, downArrow, ...rest }) {
  const HEIGHT = 28
  const [isHovering, setIsHovering] = useState(false)

  return (
    <Text
      as="button"
      className={isHovering === true ? "isHovering" : undefined}
      size={500}
      display="inline-flex"
      m={0}
      p={0}
      border={0}
      borderRadius={0}
      fontFamily="extended"
      fontSize={[14, 16, 18]}
      fontWeight={700}
      lineHeight={HEIGHT + "px"}
      color="primary"
      bg="transparent"
      css={css({
        textTransform: "uppercase",
        appearance: "none",
        cursor: "pointer",
        ":disabled": {
          opacity: 0.5,
          cursor: "not-allowed",
        },
        ".arrow--before": {
          transform: "translateX(0)",
          transition: "transform 0ms linear",
        },
        ".arrow--after": {
          transform: `translateX(-${HEIGHT}px)`,
          transition: "transform 0ms linear",
        },
        "&.isHovering": {
          ".arrow--before": {
            transform: `translateX(${HEIGHT}px)`,
            transition: "transform 200ms ease-out",
          },
          ".arrow--after": {
            transform: "translateX(0)",
            transition: "transform 200ms ease-out",
          },
        },
      })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...rest}
    >
      {/* ARROW LEFT */}
      {backArrow && (
        <Box mr={["spacing.2", "spacing.3"]}>
          <Shape size={HEIGHT} bg="primary">
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              justifyContent="center"
              alignItems="center"
              width={HEIGHT}
              height={HEIGHT}
              overflow="hidden"
              css={{ transform: "translate(-50%, -50%) rotate(180deg)" }}
            >
              <Box
                className="arrow--before"
                as="svg"
                width={(18 / 14) * 12}
                height={12}
                viewBox="0 0 18 14"
                fill="none"
                color="background"
              >
                <title>Arrow Left</title>
                <path
                  stroke="currentColor"
                  strokeWidth={2}
                  d="M0 7h16M10 13l6-6-6-6"
                />
              </Box>
            </Flex>
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              justifyContent="center"
              alignItems="center"
              width={HEIGHT}
              height={HEIGHT}
              overflow="hidden"
              css={{ transform: "translate(-50%, -50%) rotate(180deg)" }}
            >
              <Box
                className="arrow--after"
                as="svg"
                width={(18 / 14) * 12}
                height={12}
                viewBox="0 0 18 14"
                fill="none"
                color="background"
              >
                <title>Arrow Left</title>
                <path
                  stroke="currentColor"
                  strokeWidth={2}
                  d="M0 7h16M10 13l6-6-6-6"
                />
              </Box>
            </Flex>
          </Shape>
        </Box>
      )}

      {/* CHILDREN */}
      <Box as="span">{children}</Box>

      {/* ARROW RIGHT */}
      {!(downArrow || backArrow) && (
        <Box ml={["spacing.2", "spacing.3"]}>
          <Shape size={HEIGHT} bg="primary">
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              justifyContent="center"
              alignItems="center"
              width={HEIGHT}
              height={HEIGHT}
              overflow="hidden"
              css={{ transform: "translate(-50%, -50%)" }}
            >
              <Box
                className="arrow--before"
                as="svg"
                width={(18 / 14) * 12}
                height={12}
                viewBox="0 0 18 14"
                fill="none"
                color="background"
              >
                <title>Arrow Right</title>
                <path
                  stroke="currentColor"
                  strokeWidth={2}
                  d="M0 7h16M10 13l6-6-6-6"
                />
              </Box>
            </Flex>
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              justifyContent="center"
              alignItems="center"
              width={HEIGHT}
              height={HEIGHT}
              overflow="hidden"
              css={{ transform: "translate(-50%, -50%)" }}
            >
              <Box
                className="arrow--after"
                as="svg"
                width={(18 / 14) * 12}
                height={12}
                viewBox="0 0 18 14"
                fill="none"
                color="background"
              >
                <title>Arrow Right</title>
                <path
                  stroke="currentColor"
                  strokeWidth={2}
                  d="M0 7h16M10 13l6-6-6-6"
                />
              </Box>
            </Flex>
          </Shape>
        </Box>
      )}

      {/* ARROW DOWN */}
      {downArrow && (
        <Box ml={["spacing.2", "spacing.3"]}>
          <Shape size={HEIGHT} bg="primary">
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              justifyContent="center"
              alignItems="center"
              width={HEIGHT}
              height={HEIGHT}
              overflow="hidden"
              css={{ transform: "translate(-50%, -50%) rotate(90deg)" }}
            >
              <Box
                className="arrow--before"
                as="svg"
                width={(18 / 14) * 12}
                height={12}
                viewBox="0 0 18 14"
                fill="none"
                color="background"
              >
                <title>Arrow Left</title>
                <path
                  stroke="currentColor"
                  strokeWidth={2}
                  d="M0 7h16M10 13l6-6-6-6"
                />
              </Box>
            </Flex>
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              justifyContent="center"
              alignItems="center"
              width={HEIGHT}
              height={HEIGHT}
              overflow="hidden"
              css={{ transform: "translate(-50%, -50%) rotate(90deg)" }}
            >
              <Box
                className="arrow--after"
                as="svg"
                width={(18 / 14) * 12}
                height={12}
                viewBox="0 0 18 14"
                fill="none"
                color="background"
              >
                <title>Arrow Left</title>
                <path
                  stroke="currentColor"
                  strokeWidth={2}
                  d="M0 7h16M10 13l6-6-6-6"
                />
              </Box>
            </Flex>
          </Shape>
        </Box>
      )}
    </Text>
  )
}

export default Button
