import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"

const FancyLink = styled("a", { shouldForwardProp })({
  backgroundImage: `linear-gradient(180deg,transparent 92%, currentColor 0)`,
  backgroundSize: "0 100%",
  backgroundPosition: "right center",
  backgroundRepeat: "no-repeat",
  transition: "background-size 700ms cubic-bezier(0.4,0,0,1)",

  ":hover": {
    backgroundSize: "100% 100%",
    backgroundPosition: "left center",
    transition: "background-size 900ms cubic-bezier(0.4,0,0,1)",
  },
})

FancyLink.defaultProps = {
  "data-component-id": "fancyLink",
}

export default FancyLink
