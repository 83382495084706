import css from "@styled-system/css"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import React from "react"

// Project components
import { Box, ColorMode, FancyLink, Flex, Text, Wrapper } from "components"

const FormattedLink = ({ label, number, telLink = true }) => (
  <Box as="li">
    <Flex>
      {label && (
        <Text
          as="h6"
          size={500}
          mr="spacing.2"
          lineHeight={[24 + "px", 32 + "px"]}
          color="alt"
        >
          {label}
        </Text>
      )}
      <Text size={500} lineHeight={[24 + "px", 32 + "px"]}>
        <FancyLink as="a" href={(telLink ? "tel:" : "") + number}>
          {number}
        </FancyLink>
      </Text>
    </Flex>
  </Box>
)

function Footer() {
  const { contact, navigation, settings } = useStaticQuery(graphql`
    {
      navigation: prismicNavigation {
        data {
          nav_links {
            label
            link {
              url
            }
          }
        }
      }
      contact: prismicContact {
        data {
          address_suite_number
          address_building_number_and_street_name
          address_postal
          address_city
          address_province
          phone_number
          fax_number
          emergency_number
          confidenceline_number
          confidenceline_site {
            url
          }
          map_link {
            url
          }
        }
      }
      settings: prismicSettings {
        data {
          logo_dark {
            fluid(maxHeight: 128) {
              ...GatsbyPrismicImageFluid
              aspectRatio
            }
            alt
          }
          copyright
          badge {
            fluid(maxHeight: 192) {
              ...GatsbyPrismicImageFluid
              aspectRatio
            }
          }
          badge_attribution {
            url
          }
        }
      }
    }
  `)

  return (
    <ColorMode mode="light">
      <Wrapper
        data-component-id="footer"
        as="footer"
        py={["layout.6", "layout.8"]}
        bg="background"
      >
        <Box mb={["layout.4", "layout.6"]}>
          <Flex
            flexWrap="wrap"
            alignItems="center"
            m={[-16, null, -32]}
            css={css({
              "> *": {
                // width: ["100%", null, "50%"],
                p: [16, null, 32],
              },
            })}
          >
            <Box>
              {/* Teine logo. */}
              <Box
                as={Link}
                to="/"
                position="relative"
                display="block"
                width={[
                  settings.data.logo_dark.fluid.aspectRatio * 48 + "px",
                  settings.data.logo_dark.fluid.aspectRatio * 64 + "px",
                ]}
                height={[48, 64]}
              >
                <Image
                  fluid={settings.data.logo_dark.fluid}
                  alt={settings.data.logo_dark.alt}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  objectFit="cover"
                  objectPosition="center"
                />
              </Box>
            </Box>

            {/* {settings.data.badge && settings.data.badge.fluid && (
							<Box>
								<Box
									as={settings.data.badge_attribution.url ? Link : "div"}
									to={
										settings.data.badge_attribution.url
											? settings.data.badge_attribution.url
											: undefined
									}
									target={
										settings.data.badge_attribution.url ? "_blank" : undefined
									}
									rel={
										settings.data.badge_attribution.url
											? "noopener noreferrer"
											: undefined
									}
									position="relative"
									display="block"
									width={[
										settings.data.badge.fluid.aspectRatio * 64 + "px",
										settings.data.badge.fluid.aspectRatio * 96 + "px",
									]}
									height={[64, 96]}
								>
									<Image
										fluid={settings.data.badge.fluid}
										alt={settings.data.badge.alt}
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
										}}
										objectFit="cover"
										objectPosition="center"
									/>
								</Box>
							</Box>
						)} */}
          </Flex>
        </Box>

        {/* Inner Flex, holding 2 columns of data, aligned horizontally. */}
        <Flex
          flexWrap="wrap"
          m={[-16, null, -32]}
          css={css({
            "> *": {
              width: ["100%", null, "50%"],
              p: [16, null, 32],
            },
          })}
        >
          {/* Teine address. */}
          <Box as="address" css={{ fontStyle: "normal" }}>
            <a
              href={contact.data.map_link && contact.data.map_link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact.data.address_suite_number && (
                <>
                  <Text
                    as="span"
                    size={500}
                    lineHeight={[24 + "px", 32 + "px"]}
                    children={contact.data.address_suite_number}
                  />
                  <br />
                </>
              )}
              {contact.data.address_building_number_and_street_name && (
                <>
                  <Text
                    as="span"
                    size={500}
                    lineHeight={[24 + "px", 32 + "px"]}
                    children={
                      contact.data.address_building_number_and_street_name
                    }
                  />
                  <br />
                </>
              )}
              {(contact.data.address_postal ||
                contact.data.address_province ||
                contact.data.address_city) && (
                <Text
                  as="span"
                  size={500}
                  lineHeight={[24 + "px", 32 + "px"]}
                  children={
                    contact.data.address_city +
                    (contact.data.address_province &&
                      ", " + contact.data.address_province) +
                    (contact.data.address_postal &&
                      " " + contact.data.address_postal)
                  }
                />
              )}
            </a>
          </Box>

          {/* Phone, Fax and Emergency. */}
          <Box as="ul" m={0} css={{ listStyle: "none" }}>
            {contact.data.phone_number && (
              <FormattedLink label="Phone" number={contact.data.phone_number} />
            )}
            {contact.data.fax_number && (
              <FormattedLink label="Fax" number={contact.data.fax_number} />
            )}
            {contact.data.emergency_number && (
              <FormattedLink
                label="Emergency"
                number={contact.data.emergency_number}
              />
            )}
            {contact.data.confidenceline_number && (
              <FormattedLink
                label="ConfidenceLine"
                number={contact.data.confidenceline_number}
              />
            )}
            {contact.data.confidenceline_site && (
              <FormattedLink
                label=""
                number={contact.data.confidenceline_site.url}
                telLink={false}
              />
            )}
          </Box>
        </Flex>

        {/* Links, from Home to Contact. */}
        <Box mt={["layout.4", "layout.6"]}>
          <Box mx="-24px" my="-8px" css={css({ columnCount: [2, null, 4] })}>
            {navigation.data.nav_links &&
              navigation.data.nav_links.map(({ label, link }, index) => (
                <Text
                  size={500}
                  css={css({
                    px: "24px",
                    py: "8px",
                  })}
                  key={"link" + index}
                >
                  <FancyLink as={Link} to={link && link.url}>
                    {label}
                  </FancyLink>
                </Text>
              ))}
            {/* Shareholders link: Not tied to Prismic */}
            <Text
              size={500}
              css={css({
                px: "24px",
                py: "8px",
              })}
            >
              <FancyLink as={Link} to="/shareholders">
                Shareholders
              </FancyLink>
            </Text>
          </Box>
        </Box>

        {/* Copyright */}
        <Box mt={["layout.4", "layout.6"]}>
          <Text size={300} color="alt">
            © {new Date().getFullYear()}{" "}
            {settings.data.copyright || "Teine Energy Ltd."}{" "}
            {/* <Box
              as="a"
              href="https://builtbyfield.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Design+code by Field.
            </Box> */}
            <FancyLink as={Link} to="/privacy/">
              Privacy
            </FancyLink>
            {" · "}
            <FancyLink as={Link} to="/legal/">
              Legal
            </FancyLink>
            {" · "}
            <FancyLink as={Link} to="/code-of-conduct/">
              Code of Conduct
            </FancyLink>
          </Text>
        </Box>
      </Wrapper>
    </ColorMode>
  )
}

export default Footer
