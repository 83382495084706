import React, { useState, useEffect, useCallback } from "react"
import { motion, useViewportScroll } from "framer-motion"
import { Link } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"

import {
  AspectRatio,
  Box,
  Button,
  ColorMode,
  Flex,
  Heading,
  RichText,
  TriggerWrapper,
  Wrapper,
} from "components"

// Animation variables
const DURATION = 1.6
const DELAY = 0.3

// Design variables
const SECOND_IMAGE_OFFSET = 64
const COLLAGE_GAP = 128

function ContentComp({ title, body, buttonLabel, buttonLink, index }) {
  return (
    <Box
      position="relative"
      pr={[0, null, null, index % 2 === 0 ? SECOND_IMAGE_OFFSET : 0]}
      pl={[0, null, null, index % 2 === 0 ? 0 : SECOND_IMAGE_OFFSET]}
    >
      <Heading children={title} size={700} />
      <RichText size={500} content={{ html: body }} mt="layout.4" />
      {buttonLabel && buttonLink && (
        <Button as={Link} to={buttonLink} mt="layout.4">
          {buttonLabel}
        </Button>
      )}
    </Box>
  )
}

function ImageComp({ velocity, image, ratio, index, ...rest }) {
  const { scrollY } = useViewportScroll()
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [wrapper, setWrapper] = useState(undefined)

  // Set wrapper variable to be the measurement div
  const measuredRef = useCallback(node => {
    setWrapper(node)
  }, [])

  // Track scroll position-- move percentage from top of screen to bottom
  useEffect(() => {
    if (wrapper) {
      // On IE, force show state immediately to avoid performance issues
      if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1
      ) {
        setScrollPercentage(0.5)
      } else {
        scrollY.onChange(v => {
          const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
          setScrollPercentage(-wrapper.getBoundingClientRect().top / height + 1)
          // setScrollPercentage(v / height)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapper])

  return (
    <div ref={measuredRef}>
      <AspectRatio ratio={ratio} overflow="hidden" {...rest}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width="100%"
          height={index % 3 === 2 ? "160%" : "150%"}
          css={{ transform: "translate(-50%, -50%)", overflow: "hidden" }}
        >
          <Image
            fluid={image.fluid}
            alt={image.alt}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transform:
                "translate3d(0, " +
                (10 * -velocity + scrollPercentage * 10 * velocity) +
                "%, 0)",
            }}
            objectFit="cover"
            objectPosition="center"
          />
        </Box>
      </AspectRatio>
    </div>
  )
}

function Images({ data, show }) {
  return (
    <Box>
      {data.imageOne && data.imageOne.fluid && (
        <Box
          position="relative"
          zIndex={1}
          width={
            data.index % 3 === 2 && `calc(100% - ${SECOND_IMAGE_OFFSET}px)`
          }
          mb={
            data.index % 3 === 0
              ? `calc(${COLLAGE_GAP / 2}px - ${SECOND_IMAGE_OFFSET}px)`
              : data.index % 2 === 1
              ? `${COLLAGE_GAP / 2}px`
              : `calc(${COLLAGE_GAP / 2}px - ${SECOND_IMAGE_OFFSET}px)`
          }
          ml={data.index % 2 === 1 && -SECOND_IMAGE_OFFSET + "px"}
          pt={data.index % 3 === 2 && 64}
          pb={
            data.index % 4 === 0
              ? (2 / 3) * 100 + "%"
              : data.index % 3 === 1
              ? 0
              : 64
          }
        >
          <motion.div
            variants={{
              initial: {
                y: 48,
              },
              enter: {
                y: 0,
                transition: {
                  y: {
                    duration: DURATION,
                    delay: DELAY,
                    ease: [0.23, 1, 0.32, 1],
                  },
                },
              },
            }}
            initial={data.index % 3 === 1 ? "enter" : "initial"}
            animate={
              data.index % 3 === 1 ? "enter" : show ? "enter" : "initial"
            }
          >
            <ImageComp
              velocity={-1}
              image={data.imageOne}
              ratio={
                data.index % 2 === 1
                  ? "1:1"
                  : data.index % 3 === 0
                  ? "3:2"
                  : "5:6"
              }
              index={data.index}
            />
          </motion.div>
          {data.imageTwo && data.imageTwo.fluid && (
            <Box
              position="absolute"
              top={
                data.index % 3 === 2
                  ? 0
                  : `calc(50% - ${SECOND_IMAGE_OFFSET}px)`
              }
              left={[
                data.index % 3 === 2
                  ? SECOND_IMAGE_OFFSET * 2
                  : SECOND_IMAGE_OFFSET / 2,
                data.index % 3 === 2
                  ? SECOND_IMAGE_OFFSET * 3
                  : SECOND_IMAGE_OFFSET,
              ]}
              width={
                data.index % 3 === 2
                  ? `calc(100% - ${SECOND_IMAGE_OFFSET}px)`
                  : "100%"
              }
            >
              <motion.div
                variants={{
                  initial: {
                    y: -48,
                  },
                  enter: {
                    y: 0,
                    transition: {
                      y: {
                        duration: DURATION,
                        delay: DELAY,
                        ease: [0.23, 1, 0.32, 1],
                      },
                    },
                  },
                }}
                initial={data.index % 3 === 1 ? "enter" : "initial"}
                animate={
                  data.index % 3 === 1 ? "enter" : show ? "enter" : "initial"
                }
              >
                <ImageComp
                  velocity={1}
                  image={data.imageTwo}
                  ratio={data.index % 3 === 0 ? "3:2" : "5:6"}
                  index={data.index}
                />
              </motion.div>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

function GridRow({ data }) {
  return (
    <Box className="gridRow-slice" as="section" position="relative">
      {/* ~~~~~~~~~~ BACKGROUNDS ~~~~~~~~~~ */}
      {(data.topBackground === "Gray" || data.topBackground === "Black") && (
        <ColorMode mode={data.topBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            top={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="50%"
            bg={data.topBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>
      )}
      {(data.bottomBackground === "Gray" ||
        data.bottomBackground === "Black") && (
        <ColorMode mode={data.bottomBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            bottom={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="50%"
            bg={data.bottomBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>
      )}

      <Wrapper
        position="relative"
        mt={[0, null, null, data.index === 0 && 128]}
      >
        {data.index % 2 === 0 && (
          <Box
            position="absolute"
            top={[64, null, null, -128]}
            left={[0, 32]}
            width={["100vw", "calc(100vw - 64px)"]}
            height="100%"
            bg="wash"
          />
        )}
        <Flex
          flexWrap="wrap"
          mx={-32}
          css={css({
            "> *": {
              width: ["100%", null, null, "50%"],
              px: 32,
            },
          })}
        >
          <Box order={[1, null, null, data.index % 2 === 0 ? -1 : 1]}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              mt={[0, null, null, COLLAGE_GAP / 2]}
              pb={[64, null, null, 0]}
            >
              <ContentComp
                title={data.heading}
                body={data.body}
                buttonLabel={data.button}
                buttonLink={data.buttonLink}
                index={data.index}
              />
            </Flex>
          </Box>
          <TriggerWrapper>
            <Images data={data} />
          </TriggerWrapper>
        </Flex>
      </Wrapper>
    </Box>
  )
}

export default GridRow
