// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-shareholders-forgot-index-js": () => import("./../../../src/pages/shareholders/forgot/index.js" /* webpackChunkName: "component---src-pages-shareholders-forgot-index-js" */),
  "component---src-pages-shareholders-index-js": () => import("./../../../src/pages/shareholders/index.js" /* webpackChunkName: "component---src-pages-shareholders-index-js" */),
  "component---src-pages-shareholders-portal-index-js": () => import("./../../../src/pages/shareholders/portal/index.js" /* webpackChunkName: "component---src-pages-shareholders-portal-index-js" */),
  "component---src-pages-shareholders-reset-index-js": () => import("./../../../src/pages/shareholders/reset/index.js" /* webpackChunkName: "component---src-pages-shareholders-reset-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-templates-news-item-index-js": () => import("./../../../src/templates/news-item/index.js" /* webpackChunkName: "component---src-templates-news-item-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */)
}

