import React from "react"

import { Box } from "components"

function Shape({ bg, children, image, size, ...rest }) {
  return (
    <Box
      data-component-id="shape"
      position="relative"
      width={size}
      height={size}
      {...rest}
    >
      {!image && (
        <Box
          bg={bg}
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: 9999,
            // clipPath: "url(#octagonClip)",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export default Shape
