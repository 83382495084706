import React, { createContext, useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import GoTrue from "gotrue-js"

export const AuthContext = createContext()

const AUTH = new GoTrue({
  APIUrl: "https://teine.netlify.app/.netlify/identity",
  audience: "",
  setCookie: false,
})

export function AuthProvider({ children }) {
  // Internal State && Reqs
  const [user, setUser] = useState({})
  const [userState, setUserState] = useState("notFound")

  useEffect(() => {
    const authedUser = AUTH.currentUser()
    if (authedUser) {
      setUser(authedUser)
    }
    // On start check for a recovery token in the header. If present, recover account and redirect to the change password page
    if (window.location.hash.startsWith("#recovery_token=")) {
      AUTH.recover(window.location.hash.replace("#recovery_token=", ""))
        .then(response => {
          navigate("/shareholders/reset")
        })
        .catch(error => navigate("/shareholders/reset?error=true"))
    }
  }, [])

  // Utilize the GoTrue Netlify Identity instance to login a user
  const login = (email, password, failureCallback) => {
    AUTH.login(email, password)
      .then(response => {
        setUser(response)
        navigate("/shareholders/portal/")
      })
      .catch(error => {
        if (failureCallback) {
          failureCallback()
        }
      })
  }

  // Utilize the GoTrue Netlify Identity instance to sign up a user
  const register = (
    email,
    password,
    name,
    correspondencePreference,
    failureCallback
  ) => {
    AUTH.signup(email, password, {
      name: name,
      correspondencePreference: correspondencePreference,
    })
      .then(response => {
        setUser(response)
        navigate("/shareholders/portal/")
      })
      .catch(error => {
        if (failureCallback) {
          failureCallback()
        }
      })
  }

  // Utilize the GoTrue Netlify Identity instance to send a forgot password message
  const forgotPassword = (email, callback, failureCallback) => {
    AUTH.requestPasswordRecovery(email)
      .then(response => {
        if (callback) {
          callback()
        }
      })
      .catch(error => {
        if (failureCallback) {
          failureCallback()
        }
      })
  }

  // Utilize the GoTrue Netlify Identity instance to reset your password
  const updateUser = (update, callback, failureCallback) => {
    const user = AUTH.currentUser()
    user
      .update(update, callback, failureCallback)
      .then(user => {
        setUser(user)
        if (callback) {
          callback()
        }
      })
      .catch(error => {
        if (failureCallback) {
          failureCallback()
        }
      })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userState,
        login,
        register,
        forgotPassword,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
