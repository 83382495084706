import React, { useState, useEffect, useCallback } from "react"
import { motion, useViewportScroll } from "framer-motion"

function SlideWrapper({
  direction,
  children,
  duration,
  delayInitialCheck,
  delay,
  ...rest
}) {
  const slideUp = {
    initial: {
      y: 64,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        opacity: {
          duration: duration,
          delay: delay,
          ease: [0.23, 1, 0.32, 1],
        },
        y: {
          duration: duration * 2,
          delay: delay,
          ease: [0.23, 1, 0.32, 1],
        },
      },
    },
  }

  const slideDown = {
    initial: {
      y: -64,
    },
    enter: {
      y: 0,
      transition: {
        y: {
          duration: duration * 3,
          delay: delay,
          ease: [0.23, 1, 0.32, 1],
        },
      },
    },
  }

  const [show, setShow] = useState(false)
  const [wrapper, setWrapper] = useState(undefined)
  const measuredRef = useCallback(
    node => {
      setTimeout(() => {
        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight
        if (node !== null) {
          if (node.getBoundingClientRect().top <= height - height / 4) {
            setShow(true)
          } else {
            setWrapper(node)
          }
        }
      }, delayInitialCheck)
    },
    [delayInitialCheck]
  )
  const { scrollY } = useViewportScroll()

  useEffect(() => {
    if (wrapper) {
      // On IE, force show state immediately to avoid performance issues
      if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1
      ) {
        setShow(true)
      } else {
        scrollY.onChange(v => {
          const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
          if (
            wrapper.getBoundingClientRect().top <= height - height / 4 &&
            !show
          ) {
            setShow(true)
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapper])

  return (
    <div ref={measuredRef} {...rest}>
      <motion.div
        variants={direction === "up" ? slideUp : slideDown}
        initial="initial"
        animate={show ? "enter" : "initial"}
      >
        {children}
      </motion.div>
    </div>
  )
}

SlideWrapper.defaultProps = {
  direction: "up",
  delay: 0,
  delayInitialCheck: 600,
  duration: 1,
}

export default SlideWrapper
