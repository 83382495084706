import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@emotion/react"
import Helmet from "react-helmet"
import { motion, AnimatePresence } from "framer-motion"

// Project components
import { Box, Footer, GlobalStyles, Header, Theme } from "components"
import { AuthProvider } from "contexts/auth"

import "./fonts.css"

function Layout({ children, location }) {
  return (
    <AuthProvider>
      <ThemeProvider theme={Theme}>
        <>
          <Helmet>
            {/* Enable iPhone X and greater env() padding for properly avoiding bottom bar */}
            <meta
              name="viewport"
              content="initial-scale=1, viewport-fit=cover"
            />
          </Helmet>

          <GlobalStyles />

          {/* Shape clipping mask */}
          {/* We place this here to make it globally available to all elements that reference #octagonClip */}
          <Box
            as="svg"
            data-component-id="octagon.svg"
            position="absolute"
            width="0"
            height="0"
            opacity={0}
          >
            <defs>
              <clipPath id="octagonClip" clipPathUnits="objectBoundingBox">
                <polygon
                  points="0.3 0, 0.7 0, 1 0.3, 1 0.7, 0.7 1, 0.3 1, 0 0.7, 0 0.3"
                  transform="rotate(22.5 0.5 0.5) scale(0.925) translate(0.04 0.04)"
                />
              </clipPath>
            </defs>
          </Box>

          {/* Header section. */}
          <Header path={location.pathname} />

          {/* Page content. */}
          <AnimatePresence>
            <motion.main
              variants={{
                initial: {
                  opacity: 0,
                },
                enter: {
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                    // delay: 0.3,
                    when: "beforeChildren",
                  },
                },
                exit: {
                  opacity: 0,
                  transition: { duration: 0.3 },
                  when: "afterChildren",
                },
              }}
              key={location.pathname}
              initial="initial"
              animate="enter"
              exit="exit"
              css={{
                overflow: "hidden",
              }}
            >
              {children}
            </motion.main>
          </AnimatePresence>

          {/* Footer section. */}
          <Footer data={{}} />
        </>
      </ThemeProvider>
    </AuthProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
