import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"

import {
  AspectRatio,
  Box,
  ColorMode,
  FadeWrapper,
  FancyLink,
  Heading,
  RichText,
  Wrapper,
} from "components"

const PageHeader = ({ data }) => (
  <Box className="page-title" as="section" pt={[128, 224]}>
    {/* ~~~~~~~~~~ CONTENT ~~~~~~~~~~ */}
    <Wrapper position="relative">
      <FadeWrapper>
        <Box maxWidth={1080 / 1.5}>
          {/* Breadcrumb */}
          {data.breadcrumb && (
            <Heading size={600} mb="layout.4">
              <FancyLink
                children={data.breadcrumb.label}
                as={Link}
                to={data.breadcrumb.to}
              />
            </Heading>
          )}
          {/* Heading Text */}
          {data.heading && (
            <RichText size={500} content={{ html: data.heading }} />
          )}

          {/* Text Block */}
          {data.description && (
            <RichText
              size={500}
              content={{ html: data.description }}
              maxWidth={1080 / 2}
              mt={data.heading && ["layout.2", "layout.4"]}
            />
          )}
          {/* Children */}
          {data.children && data.children}
        </Box>
      </FadeWrapper>
    </Wrapper>

    {/* Image */}
    {data.image && (
      <Box position="relative" mt={["layout.6", "layout.7"]}>
        {/* ~~~~~~~~~~ BACKGROUND ~~~~~~~~~~ */}
        <ColorMode mode={data.bottomBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            bottom={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="25%"
            bg={data.bottomBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>

        {/* ~~~~~~~~~~ IMAGE ~~~~~~~~~~ */}
        <FadeWrapper>
          <Wrapper
            className="page-title__image"
            position="relative"
            maxWidth={1080 + 64 * 2}
          >
            {/* Image */}
            {data.image && data.image.fluid && (
              <Box>
                <AspectRatio ratio="16:9" overflow="hidden">
                  <Image
                    fluid={data.image.fluid}
                    alt={data.image.alt}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      // Animate this
                      transform: "scale3d(1.2, 1.2, 1)",
                    }}
                    objectFit="cover"
                    objectPosition="center"
                  />
                </AspectRatio>
              </Box>
            )}
          </Wrapper>
        </FadeWrapper>
      </Box>
    )}
  </Box>
)

export default PageHeader
