import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import css from "@styled-system/css"
import { useTheme } from "@emotion/react"
import { rgba } from "polished"

import { Box, Heading } from "components"

function AccordionGroup({ id, items, onChange, preExpanded, ...rest }) {
  const theme = useTheme()

  return (
    <Accordion allowZeroExpanded={true} preExpanded={preExpanded} {...rest}>
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + index}
          uuid={"accordionGroup" + index}
          id={item.id}
          css={css({
            py: ["layout.2", null, null, "layout.4"],
            borderBottom: "1px solid",
            borderColor: rgba(theme.colors.text, 0.2),
            "&:last-of-type": {
              borderBottom: "none",
            },
          })}
          onClick={() => {
            if (onChange) {
              onChange(item.object)
            }
          }}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              css={css({
                display: "flex",
                cursor: "pointer",
                "&:focus": { outline: 0 },
                ".octagonControl": {
                  opacity: 0.25,
                  transition: "opacity 400ms ease-out",
                  ".octagonControl__container": {
                    fill: "none",
                    stroke: "text",
                    transition: "fill 400ms ease-out",
                  },
                  ".octagonControl__arrow": {
                    stroke: "text",
                    transformOrigin: "50% 50%",
                    transition:
                      "stroke 200ms ease-out, transform 200ms ease-out",
                  },
                },
                // Effects when accordion is opened
                '&[aria-expanded="true"]': {
                  ".octagonControl": {
                    opacity: 1,
                    ".octagonControl__container": {
                      fill: "text",
                    },
                    ".octagonControl__arrow": {
                      transform: "rotate(180deg)",
                      stroke: "background",
                    },
                  },
                },
              })}
            >
              <Box flex={1} mr="layout.1">
                <Heading
                  children={item.heading}
                  as="span"
                  size={600}
                  display="block"
                />
                {item.subheading && (
                  <Heading
                    children={item.subheading}
                    as="span"
                    size={600}
                    display="block"
                    mt="layout.1"
                    color="alt"
                  />
                )}
              </Box>
              <Box className="octagonControl">
                <Box
                  as="svg"
                  display="block"
                  width={[32, 40, 40]}
                  height={[32, 40, 40]}
                  viewBox="0 0 48 48"
                  strokeWidth={2}
                  fill="none"
                >
                  <path
                    d="M19.5139 1.44668L24 1.00484L28.4861 1.44668L32.7999 2.75524L36.7754 4.88023L40.26 7.73996L43.1198 11.2246L45.2448 15.2001L46.5533 19.5139L46.9952 24L46.5533 28.4861L45.2448 32.7999L43.1198 36.7754L40.26 40.26L36.7754 43.1198L32.7999 45.2448L28.4861 46.5533L24 46.9952L19.5139 46.5533L15.2001 45.2448L11.2246 43.1198L7.73996 40.26L4.88023 36.7754L2.75524 32.7999L1.44668 28.4861L1.00484 24L1.44668 19.5139L2.75524 15.2001L4.88022 11.2246L7.73997 7.73997L11.2246 4.88022L15.2001 2.75524L19.5139 1.44668Z"
                    className="octagonControl__container"
                  />
                  <path
                    d="M34 27L24 17L14 27"
                    className="octagonControl__arrow"
                  />
                </Box>
              </Box>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            css={css({ pt: ["layout.2", null, null, "layout.4"] })}
          >
            {item.children}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: [],
}

export default AccordionGroup
