const transitionDelay = 300

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(1, 0), transitionDelay + 50)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [1, 0])),
      transitionDelay
    )
  }
  return false
}

// export const onClientEntry = async () => {
//   if (typeof IntersectionObserver === `undefined`) {
//     await import(`intersection-observer`)
//   }
// }
