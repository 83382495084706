import React from "react"
import { Global } from "@emotion/react"
import css from "@styled-system/css"
import { normalize } from "polished"

const GlobalStyles = () => (
  <Global
    styles={css({
      ...normalize(),
      "*": { boxSizing: "border-box" },
      "::selection": { color: "#fff", backgroundColor: "primary" },
      body: {
        m: 0,
        fontFamily: "regular",
        color: "text",
        bg: "background",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
      },
      a: {
        textDecoration: "none",
        color: "inherit",
      },
      "::-moz-focus-inner, ::-moz-focus-inner": {
        padding: 0,
        border: 0,
      },
    })}
  />
)

export default GlobalStyles
